<template>
  <div>
    <el-table :data="tableData" border>
      <el-table-column :prop="$l('globalValue.en','name')" :label="$l('globalValue.key','名称')"></el-table-column>
      <el-table-column prop="fvalue" :label="$l('globalValue.value','值')" align="center"></el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" width="100">
        <template slot-scope="scope">
          <el-button type="primary" @click="$refs.editPage.open(scope.row)">{{$l('common.edit','编辑')}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <value-edit ref="editPage" @save-success="getList"></value-edit>
  </div>
</template>
<script>
  import ValueEdit from "@/views/globalValue/ValueEdit";

  export default {
    components: {ValueEdit},
    data() {
      return {
        tableData: [],
      };
    },
    mounted() {
      this.getList();
    },
    methods: {
      getList() {
        this.$http.get("globalValues").then(data => {
          this.tableData = data;
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
