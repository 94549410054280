<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="$l('common.edit','编辑')"
    width="400px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      :model="model"
      class="form"
      label-width="60px">
      <el-form-item :label="$l('globalValue.value','值')" prop="fvalue" :rules="$rule.notNull">
        <el-input v-model="model.fvalue" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l('common.cancel','取消')}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l('common.save','保存')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>

  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        model: {
          id: 0,
          fvalue: "",
        },
      };
    },
    methods: {
      open(data) {
        this.model.id = data.id;
        this.model.fvalue = data.fvalue;
        this.dialogVisible = true;
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .save("systemValues", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
